export const RtkqTagEnum = {
  AUTH_USER: "AUTH_USER",
  ADMIN: "ADMIN",
  INVESTORS: "INVESTORS",
  UPLOAD_MEDIA: "UPLOAD_MEDIA",
  PROPERTY: "PROPERTY",
  MEDIA: "MEDIA",
  FINANCE: "FINANCE",
  FEEDBACKS: "FEEDBACKS",
};

export const Endpoints = {
  LOGIN: "/admin/login",
  INVESTORS: "/admin/users?page=1&limit=20",
  UPDATE_PASSWORD: "/admin/update-password",
  UPLOAD_PROPERTY_IMAGES: "/admin/upload-property-images",
  UPLOAD_PROPERTY_DOCUMENTS: "/admin/upload-property-documents",
  CREATE_PROPERTY: "/admin/create-property",
  PROPERTY: "/admin/property",
  PROPERTY_DOCUMENTS: "/admin/property-documents",
  PROPERTIES: "/admin/get-properties",
  CREATE_ADMIN: "/admin/register",
  ADMINS: "/admin/admins",
  ADMIN: "/admin/admin-by-id",
  MEDIA_LIST: "/admin/media-list",
  USER_INVESTMENTS: "/admin/get-user-investments",
  FINANCE: "/admin/transactions",
  TRANSACTIONS: "/admin/transactions",
  DASHBOARD_SUMMARY: "/admin/dashboard-summary",
  DASHBOARD_REVENUE: "/admin/dashboard-revenue",
  UPDATE_INVESTMENT_APPROVAL_STATUS:
    "/admin/update-investment-disbursement-status",
  UPLOAD_ADS_INDEX: "/admin/upload-public-ads-by-index",
  PUBLIC_ADS_IMAGES: "/public/settings/assets",
  USER_WALLET: "/admin/get-user-wallet",
  DELETE_MEDIA: "/admin/delete-media",
  FEEDBACKS: "/admin/feedbacks",
};

export const PropertyCategoryEnum = {
  PRIMARY: "PRIMARY",
  SECONDARY: "SECONDARY",
};
export const PropertyTypeEnum = {
  APARTMENT: "APARTMENT",
  LAND: "LAND",
  BUILDING: "BUILDING",
};
export const ApprovalStatusEnum = {
  APPROVED: "APPROVED",
  PENDING: "PENDING",
  DECLINED: "DECLINED",
};
export const RoutePaths = {
  DASHBOARD: "/",
  PROPERTY: "/property",
  EDIT_PROPERTY: "/property/property-edit",
  PROPERTY_INFORMATION: "/property/information",
  FEEDBACK: "/feed-back",
  INVESTORS: "/investors",
  INVESTORS_INFORMATION: "/investors/information",
  CREATE_PROPERTY: "/property/create-property",
  ANALYTICS: "/analytics",
  ADMIN_MANAGEMENT: "/admins",
  FINANCIALS: "/financials",
  TRANSACTIONS: "/transactions",
  HELP: "/gethelp",
  SETTINGS: "/settings",
};

export const AdminTypeEnum = {
  SUPER: "SUPER",
  SUB: "SUB",
  ADMIN: "ADMIN",
  FINANCE: "FINANCE",
};

export const MediaTypeEnum = {
  IMAGE: "property-images",
  DOCUMENT: "property-documents",
};
export const TransactionStatusEnum = {
  ALL: "ALL",
  SUCCESS: "SUCCESS",
  PENDING: "PENDING",
  FAILED: "FAILED",
  REVERSED: "REVERSED",
  ABANDONED: "ABANDONED",
};
