import LoadingBackdrop from "components/LoadingBackdrop";
import useAuthUser from "hooks/useAuthUser";
import useLoadingModal from "hooks/useLoadingModal";
import { lazy, Suspense } from "react";
const NotAuthenticated = lazy(() => import("../pages/NotAuthenticated"));
const Authenticated = lazy(() => import("../pages/Authenticated"));

export default function Router() {
  const user = useAuthUser();

  const { isLoadingModal } = useLoadingModal();

  return (
    <div>
      <LoadingBackdrop open={isLoadingModal} />
      <Suspense
        fallback={
          <divx
            className=" flex justify-center mt-10 items-center mx-auto p-4 animate-spin w-6 h-6 border-[3px] border-current border-t-transparent text-blue-600 rounded-full"
            role="status"
            aria-label="loading"
          >
            <span className="flex items-center justify-center sr-only ">
              Loading...
            </span>
          </divx>
        }
      >
        {user ? <Authenticated /> : <NotAuthenticated />}
      </Suspense>
    </div>
  );
}
